<template>
  <div id="member-subscription-list">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>
          {{ PageTitle }}
        </h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="3">
                <label><span class="text-danger">*</span> From Year</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="FromYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="FromYearCode"
                  hint="Select Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label><span class="text-danger">*</span> To Year</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="ToYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="ToYearCode"
                  hint="Select Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label><span class="text-danger">*</span> Zone</label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label><span class="text-danger">*</span> LOM</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  required
                  chips
                  multiple
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="generateReportExcel"
                  :loading="ExcelLoadingFlag"
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  target="_blank"
                  color="#ff7f50"
                >
                  Generate Report
                </v-btn>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelFileName != '' && ExcelFileFlag == true"
                  :href="ExcelFileName"
                  target="_blank"
                  color="#2fb65a"
                >
                  <v-icon dark> mdi-download </v-icon> Download Report
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <upload-progress
                  :ProgessStart="progessStart"
                  :UploadPercentage="uploadPercentage"
                ></upload-progress>
              </v-col>
            </v-row> -->
          </v-form>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import StaffCreate from "@/view/pages/erp/staff/StaffCreate.vue";

export default {
  mixins: [common],
  components: {
    StaffCreate,
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      ExcelLoadingFlag: false,
      ProgressLoadingFlag: false,

      FromYearCodeRules: [(v) => !!v || "From Year is required"],
      FromYearCode: "",

      ToYearCodeRules: [(v) => !!v || " To Year is required"],
      ToYearCode: "",

      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [(v) => !!v || "Lom is required"],
      LomCode: [],
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      ExcelFileName: "",
      ExcelFileFlag: false,

      progessStart: 0,
      uploadPercentage: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        this.YearName = YearName;

        this.getZoneCodeOptions();
        this.getJciYearCodeOptions();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    JciYearCodeOptions: function () {
      this.LoadingFlag = false;
      console.log("watch JciYearCodeOptions");
      this.JciYearCodeOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lo_report",
        Action: "approval",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    generateReportExcel() {
      console.log("generateReportExcel is called");

      var validate1 = this.$refs.form2.validate();
      var ZoneCode = this.ZoneCode;
      var LomCode = this.LomCode;
      var FromYearCode = this.FromYearCode;
      var ToYearCode = this.ToYearCode;
      console.log(
        "validate1=" +
          validate1 +
          ", ZoneCode=" +
          ZoneCode +
          ", FromYearCode=" +
          FromYearCode +
          ", ToYearCode=" +
          ToYearCode
      );

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelLoadingFlag = true;
        this.ProgressLoadingFlag = true;
        this.ExcelFileFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/reports/consolidate-members";
        var upload = {
          UserInterface: 1,
          Zone: this.ZoneCode,
          Lom: this.LomCode,
          FromYear: this.FromYearCode,
          ToYear: this.ToYearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.progessStart = 1;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.ExcelLoadingFlag = false;
            thisIns.ProgressLoadingFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelFileFlag = true;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ExcelLoadingFlag = false;
            thisIns.ProgressLoadingFlag = false;
            thisIns.progessStart = 0;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#member-subscription-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>